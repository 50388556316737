.max-w-screen-xl{
    background: linear-gradient(123deg, #2E99B0 0%, #2E99B0 40%, #FCD77F calc(40% + 1px), #FCD77F 60%, #FF2E4C calc(60% + 1px), #FF2E4C 75%, #1E1548 calc(75% + 1px), #1E1548 100%);
    border-radius: 10px;
    color: #fff;
}
.uration-200{
    color: #fff;
}
.py-4{
    margin-top: 180px;
}
